.Podium .form-group {
  margin: 0;
}

.Podium td.pad-select {
  padding: 8px 19px 8px 11px;
  text-align: left;
}

.Podium td.has-select {
  padding: 2px;
}

.Podium .form-toggle {
  display: inline-block;
  margin-right: 10px;
}

.Podium p.no-tag {
  color: #999;
  margin: 0;
  padding: 0;
}

td.gold {
  background: #fff2cc;
}

td.silver {
  background: #efefef;
}

td.bronze {
  background: #fce5cd;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.LoaderDiv {
  padding: 80px 0;
  text-align: center;
}

.LoaderDiv .spinning.glyphicon {
  color: #999;
  font-size: 5rem;
  -webkit-animation: spin 2s infinite linear;
          animation: spin 2s infinite linear;
}

@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

.NoData p {
  color: #999;
  text-align: center;
  padding: 80px 0;
}

.Dashboard h3.fan-card {
  text-align: center;
  margin: 0;
}

.Comparison .form-group {
	margin: 0;
}

.Comparison .form-toggle {
  display: inline-block;
  margin-right: 10px;
}

.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .form-group {
	margin: 0;
}

.Home .form-toggle {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
}

.Indexing .info {
  padding: 80px 0;
  text-align: center;
}

.Indexing .info p {
  color: #999;
}

.Indexing .form-group {
  margin: 0;
}

.Indexing td.medium-cell {
  min-width: 120px;
}

.Indexing td.wide-cell {
  min-width: 180px;
}

table.alternating-table tbody tr:nth-child(2n) td {
  background: #ffffff;
}

table.alternating-table tbody tr:nth-child(2n+1) td {
  background: #f8f8f8;
}

.Posts .posts {
  text-align: center;
  padding-bottom: 30px;
}

.Posts .posts h2 {
  margin: 0;
}

table.basic-table {
  margin: 0 auto;
  border-collapse: collapse;
}

table.basic-table thead {
  cursor: pointer;
}

table.basic-table td,
table.basic-table th {
  padding: 5px;
  min-width: 120px;
  border: 1px solid black;
}

table.basic-table th {
  background: #fcfcfc;
}

table.basic-table th {
  text-align: center;
}

table.basic-table td {
  text-align: right;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

.Milestones td.release-0 {
  background: #9e9ac8;
}

.Milestones td.release-1 {
  color: #666;
  background: #bcbddc;
}

.Milestones td.release-2 {
  color: #999;
  background: #dadaeb;
}

.Milestones td.release-3 {
  color: #bbb;
  background: #efedf5;
}

.Milestones td.mention-0 {
  background: #74c476;
}

.Milestones td.mention-1 {
  color: #666;
  background: #a1d99b;
}

.Milestones td.mention-2 {
  color: #999;
  background: #c7e9c0;
}

.Milestones td.mention-3 {
  color: #bbb;
  background: #e5f5e0;
}

.Milestones td.show-0 {
  background: #9e9ac8;
}

.Milestones td.show-1 {
  color: #666;
  background: #bcbddc;
}

.Milestones td.show-2 {
  color: #999;
  background: #dadaeb;
}

.Milestones td.show-3 {
  color: #bbb;
  background: #efedf5;
}

.MultiArtist .form-group {
	margin: 0;
}

.MultiArtist .form-toggle {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
}

.MultiArtist .info {
  padding: 80px 0;
  text-align: center;
}

.MultiArtist .info p {
  color: #999;
}

.Podium .form-group {
  margin: 0;
}

.Podium td.pad-select {
  padding: 8px 19px 8px 11px;
  text-align: left;
}

.Podium td.has-select {
  padding: 2px;
}

.Podium .form-toggle {
  display: inline-block;
  margin-right: 10px;
}

.Podium p.no-tag {
  color: #999;
  margin: 0;
  padding: 0;
}

td.gold {
  background: #fff2cc;
}

td.silver {
  background: #efefef;
}

td.bronze {
  background: #fce5cd;
}

.ShowEditor .form-group {
  margin: 0;
}

.ShowEditor table.basic-table td,
.ShowEditor table.basic-table th {
  min-width: 0;
}

.ShowEditor button.has-right {
  margin-right: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}


.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .form-group {
	margin: 0;
}

.Home .form-toggle {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
}

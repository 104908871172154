.Posts .posts {
  text-align: center;
  padding-bottom: 30px;
}

.Posts .posts h2 {
  margin: 0;
}

table.basic-table {
  margin: 0 auto;
  border-collapse: collapse;
}

table.basic-table thead {
  cursor: pointer;
}

table.basic-table td,
table.basic-table th {
  padding: 5px;
  min-width: 120px;
  border: 1px solid black;
}

table.basic-table th {
  background: #fcfcfc;
}

table.basic-table th {
  text-align: center;
}

table.basic-table td {
  text-align: right;
}

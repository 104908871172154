.Milestones td.release-0 {
  background: #9e9ac8;
}

.Milestones td.release-1 {
  color: #666;
  background: #bcbddc;
}

.Milestones td.release-2 {
  color: #999;
  background: #dadaeb;
}

.Milestones td.release-3 {
  color: #bbb;
  background: #efedf5;
}

.Milestones td.mention-0 {
  background: #74c476;
}

.Milestones td.mention-1 {
  color: #666;
  background: #a1d99b;
}

.Milestones td.mention-2 {
  color: #999;
  background: #c7e9c0;
}

.Milestones td.mention-3 {
  color: #bbb;
  background: #e5f5e0;
}

.Milestones td.show-0 {
  background: #9e9ac8;
}

.Milestones td.show-1 {
  color: #666;
  background: #bcbddc;
}

.Milestones td.show-2 {
  color: #999;
  background: #dadaeb;
}

.Milestones td.show-3 {
  color: #bbb;
  background: #efedf5;
}

.Indexing .info {
  padding: 80px 0;
  text-align: center;
}

.Indexing .info p {
  color: #999;
}

.Indexing .form-group {
  margin: 0;
}

.Indexing td.medium-cell {
  min-width: 120px;
}

.Indexing td.wide-cell {
  min-width: 180px;
}

table.alternating-table tbody tr:nth-child(2n) td {
  background: #ffffff;
}

table.alternating-table tbody tr:nth-child(2n+1) td {
  background: #f8f8f8;
}

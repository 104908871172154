.ShowEditor .form-group {
  margin: 0;
}

.ShowEditor table.basic-table td,
.ShowEditor table.basic-table th {
  min-width: 0;
}

.ShowEditor button.has-right {
  margin-right: 10px;
}

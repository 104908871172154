.LoaderDiv {
  padding: 80px 0;
  text-align: center;
}

.LoaderDiv .spinning.glyphicon {
  color: #999;
  font-size: 5rem;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.MultiArtist .form-group {
	margin: 0;
}

.MultiArtist .form-toggle {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
}

.MultiArtist .info {
  padding: 80px 0;
  text-align: center;
}

.MultiArtist .info p {
  color: #999;
}
